
body{
  margin: 0;
  padding: 0;

}

.bg-principal{
  background-color: #E5E5E5;;
}

a{
  text-decoration: none;
}

#enteteNavbar a{
  color: white;
}

.colorYelloSkanPay{
  background-color: #f9ba14;
}
.colorTextSkanPay{
  color:#f9ba14;
}

.colorBlueTextSkanPay{
 color: #344b7b;
}

.blueSkanpay{
  background-color: #344b7b;
}
.active{
  background-color: #f9ba14 !important;
}
.divider{
  height: 5px;
  width: 100w;
  background-color: #344b7b;
  margin:10px 0;
}

a{
  text-decoration: none;
}
